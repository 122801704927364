$primaryBase: #212121;
$cmsBgGrayDark: #323a45;
$cmsPrimary: #0071bc;
$primaryBorder: #dce4ef;
$primaryClickable: #0071bc;
$icon-size: 30px;
$bDark: #364150;
$bDefault: #fcfdfd !default;
$bPrimary: #3598dc !default;
$primaryLightGrey: #f1f1f1;

$cmsBgWhite: #ffffff;
$cmsBgGray: #5b616b;
$cmsBgGrayLight: #aeb0b5;
$cmsBgGrayLighter: #d6d7d9;
$cmsBgGrayLightest: #f1f1f1;
$cmsDanger: #e31c3d;
$cmsPrimaryGrayLight: #aeb0b5;
$cmsPrimaryBase: #212121;
//Secondary Colors
$cmsSecondary: #02bfe7;
$cmsSecondaryAltDarkest: #046b99;
$cmsSecondaryAltDark: #00a6d2;
$cmsSecondaryAltLight: #9bdaf1;
$cmsSecondaryAltLightest: #e1f3f8;

$cmsSecondaryAltRed: #e31c3d;
$cmsSecondaryAltRedDarkest: #981b1e;
$cmsSecondaryAltRedDark: #cd2026;
$cmsSecondaryAltRedLight: #e59393;
$cmsSecondaryAltRedLighter: #edc0c0;
$cmsSecondaryAltRedLightest: #f9dede;

//Alt Colors
$cmsAltGoldDark: #ce9517;
$cmsAltGold: #fdb81e;
$cmsAltGoldLight: #f9c642;
$cmsAltGoldLighter: #fad980;
$cmsAltGoldLightest: #fff1d2;

$cmsAltGreen: #2e8540;
$cmsAltGreenLight: #4aa564;
$cmsAltGreenLighter: #94bfa2;
$cmsAltGreenLightest: #e7f4e4;

$cmsAltBlue: #205493;
$cmsAltBlueLight: #4773aa;
$cmsAltBlueLighter: #8ba6ca;
$cmsAltBlueLightest: #dce4ef;

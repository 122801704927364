/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/bootstrap';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'variables';

//html, body { height: 100%; }
html {
  overflow-y: hidden;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: $primaryBase;
}

.cm-title {
  color: $primaryBase;
  font-weight: bold;
  padding-left: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;

  .title-item {
    display: flex;
    align-items: center;
    color: #8e9bae;
    font-weight: bold;
    font-size: 16px;

    .material-icons {
      color: #8e9bae;
      font-size: 16px;

      &.primary {
        color: #212121;
      }
    }

    &.primary {
      color: #212121;
    }
  }
}

.cm-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-bottom: 16px;
  justify-content: flex-end;
}

.cm-form-row {
  @extend .row;
  @extend .mx-0;
  margin-bottom: 10px;
}

.cm-label {
  display: flex;
  align-items: center;
  flex: 1 1;
  flex-basis: 100px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 5px;
  margin-bottom: 8px;
  //text-transform: capitalize;
  vertical-align: center;

  &.secondary {
    color: #aeb0b5;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &.required {
    &:after {
      content: '*';
      color: $cmsDanger;
      font-size: 22px;
      position: inherit;
      padding: inherit;
    }
  }
}

.cm-form-label {
  @extend .col-md-4;
  @extend .col-sm-4;
  @extend .justify-content-sm-end;
  @extend .justify-content-start;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  padding: 0 26px !important;
  color: #aeb0b5;
  text-transform: capitalize;
  vertical-align: center;

  &.align-right {
    text-align: end;
    justify-content: flex-end;
  }

  &.required {
    &:after {
      content: '*';
      color: $cmsDanger;
      font-size: 22px;
      position: absolute;
      right: 13px;
    }
  }
}

.disabled {
  opacity: 0.5;
}

.cm-field {
  flex: 10;
  flex-shrink: 2;
  flex-basis: 200px;
  text-transform: capitalize;
  margin-left: 3px;
  display: flex;
  flex-direction: column;
}

.cm-form-field {
  display: flex;
  //align-items: center;
  flex-direction: column;
  //flex: 10;
  //flex-shrink: 2;
  //flex-basis: 200px;
  text-transform: capitalize;
  //margin-left: 10px;

  .custom-error {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #e7505a;
    animation-name: focus;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}

.cm-form-textarea {
  display: flex;
  flex-direction: column;
}

.cm-comments {
  white-space: pre-line;
}

.cm-word-wrap {
  max-width: 65%;
  overflow-wrap: break-word;
}

div[formGroupName] {
  .cm-form-field {
    //align-items: center;
  }
}

.cm-form-wrapper {
  display: flex;
  flex-direction: column;
  //border: 1px solid #5b616b;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  //padding-left: 10px;
  //margin-bottom: 50px;
}

.form-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 370px;
  //padding-right: 50px;
  .cm-row {
    flex-grow: 0;
  }

  &:nth-child(1) {
    //padding-right: 50px;
  }
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  padding: 10px 15px 10px 12px;
  width: 100%;

  &.underlined {
    border-bottom: 1px solid $primaryBorder;
    margin-bottom: 10px;
  }
}

.document-list-container {
  width: 100%;
  border: 1px solid rgba(174, 176, 181, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 0px 30px rgba(191, 219, 238, 0.2);
  border-radius: 4px;
  margin-bottom: 10px;
  max-height: 345px;
  overflow-x: auto;

  .file-item {
    min-height: 76px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 26px;
    //padding-right: 26px;
    //justify-content: space-between;
    &:not(:last-of-type) {
      border-bottom: 1px solid #efeff0;
    }

    .secondary {
      color: #aeb0b5;
    }

    .file {
      flex: 1;
      display: flex;
      align-items: center;

      &-title {
        flex-grow: 1;
        display: flex;
        align-items: center;
        color: #0071bc;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        .material-icons {
          font-size: 32px;
          color: #0071bc;
          margin-right: 10px;
        }
      }

      &-description {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }

      &-action-buttons {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .delete {
          color: $cmsDanger;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

button:focus {
  outline: none;
}

.tooltip {
  background-color: #d0ecfe !important;
  color: #212121 !important;

  &.tooltip-top::after {
    border-color: #9fcefc transparent transparent !important;
  }

  &.tooltip-right::after {
    //background-color: #0071BC;
    //border-color: #2e8540 !important;
    border-color: transparent #9fcefc transparent transparent !important;
  }
}

.cm-tooltip {
  background-color: #d0ecfe !important;
  padding: 0;

  &.tooltip-right::after {
    //background-color: #0071BC;
    //border-color: #2e8540 !important;
    border-color: transparent #9fcefc transparent transparent !important;
  }
}

.tooltip-content {
  min-height: 50px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #212121;

  &.compact {
    min-height: 20px;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #bed1de;
    margin-bottom: 5px;
  }
}

.border-bottom {
  border-bottom: 1px solid #dce4ef;
}

.form-option {
  color: $primaryClickable;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.material-icons.delete {
  color: $cmsBgGrayLight;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: $cmsDanger;
  }
}

.tag {
  display: inline-block;
  padding: 8px 8px;
  font-size: 12px;
  text-transform: capitalize;
  margin-left: 10px;
  border-radius: 30px;
  transition: all 0.2s ease;

  &.warn {
    background: #f4d35e;

    &:hover {
      background: #ddbf55;
    }
  }
}

.panel-description {
  flex-grow: 7;
  animation-name: appearance;
  animation-duration: 0.15s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  .description-field {
    display: inline-block;

    &:first-of-type {
    }

    margin-right: 50px;

    .description-label {
      display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      color: #aeb0b5;
    }

    .description-value {
      display: inline-block;
    }
  }
}

@keyframes appearance {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.panel-header-title {
  flex-grow: 1;
}

.content-info-text {
  font-size: 16px;
  padding: 15px;
  color: #323a45;
}

simple-notification {
  .simple-notification {
    padding: 10px;

    .sn-title {
      font-size: 18px;
      line-height: 20px;
      padding-right: 30px;
    }

    .sn-content {
      font-size: 14px;
      line-height: 16px;
      padding-right: 30px;
    }

    &.success {
      background-color: $cmsAltGreen;
    }

    &.error {
      background-color: $cmsSecondaryAltRedDarkest;
    }

    &.info {
      background-color: #2199e8;
    }

    .icon {
      width: 40px;
      height: 40px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
}

::ng-deep .cdk-overlay-container {
  font-family: 'Source Sans Pro', sans-serif !important;
  color: $primaryBase !important;
}

.cm-success {
  color: #227136;
}

.cm-warning {
  color: #fdb81e;
}

.cm-danger {
  color: #e31c3d;
}

.cm-primary {
  color: $cmsPrimary;
}

.tags-list-text {
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(191, 219, 238, 0.25);
  font-size: 16px;
  padding: 0px 10px;
  overflow-x: auto;
}

.submit-button {
  width: 200px;
  height: 32px;
}

.component-wrapper {
  margin: 10px 10px 10px 10px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.circular-black-spinner {
  display: inline-block !important;
  margin-left: 5px;
  vertical-align: bottom;

  svg circle {
    stroke: #000 !important;
  }
}

cms-register,
cms-login,
cms-forgot-username,
cms-forgot-password,
cms-create-password,
cms-change-password {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .margin-bottom {
    margin-bottom: 14px;
  }

  .logo-big {
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;
    cursor: pointer;

    > img {
      max-width: 250px;
      max-height: 100px;
    }
  }

  .login-container,
  .register-container,
  .forgot-container,
  .password-container {
    padding: 20px 30px;
    border: 1px solid $cmsAltBlueLightest;
    box-shadow: 0px 0px 10px 2px $cmsAltBlueLightest;
    overflow: auto;
    max-height: 95%;

    > h3 {
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
  }

  .form-container {
    .control {
      position: relative;

      label {
        user-select: none;
        margin-bottom: 0;
      }
    }

    .link {
      a {
        color: $cmsPrimary !important;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .login-btn,
  .register-btn,
  .submit-btn {
    background-color: $cmsPrimary;
    padding: 10px 16px;
    font-size: 18px;
    width: 100%;
    color: #ffffff;
    border: 1px solid $cmsPrimary;
    border-radius: 2px;
    outline: none;
    overflow: hidden;
    line-height: 1.5;
    position: relative;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.65;
    }

    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      &.not-visible {
        visibility: hidden;
      }
    }

    .spinner-rect-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;

      &.show {
        display: block;
      }

      spinner-rect {
        position: relative;
        height: 100%;

        .wave {
          top: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .password-input {
    position: relative;

    .password-visibility {
      color: #bfd4d4;
      position: absolute;
      top: 25px;
      right: 0;
      cursor: pointer;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      &:hover {
        color: $bPrimary;
      }
    }
  }

  .error-container {
    margin-top: 14px;
    text-align: right;

    .error {
      font-size: 14px;
      color: #e7505a;
    }
  }

  ng-component {
    position: absolute;
    top: 3px;
    user-select: none;

    .help-info {
      animation: none;
    }
  }
}

.default-text {
  padding-top: 20px;
  padding-left: 15px;
}

mat-paginator {
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 14px !important;
}

.item-component {
  .spinner-rect {
    position: relative;
    height: 200px;

    .wave {
      width: 100% !important;
      transform: translateY(-50%);
    }
  }
}

.mat-option-height-auto {
  &.mat-select-panel {
    .mat-option {
      height: auto !important;
    }
  }
}

.mat-expansion-panel-content {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
#zohohc-asap-web-ticketform-field-productid,
#zohohc-asap-web-ticketform-section-additional_information,
#zohohc-asap-web-ticketform-field-priority,
#zohohc-asap-web-ticketform-field-classification {
    display: none;
}

.cms-title {
  padding: 10px;
  border-bottom: 1px solid $primaryBorder;
  font-weight: bold;
}

.cms-divider {
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid $primaryBorder;
}

.case-footer-btns {
  padding: 15px;

  button {
    width: 200px !important;
  }

  .dark-info-color {
    button {
      background-color: #515b69 !important;
      border-color: #515b69 !important;
    }
  }
}

.btn-width-auto {
  button {
    width: auto !important;
  }
}

.suggestion-tooltip {
  background-color: $cmsAltGoldLighter !important;
  color: #856404 !important;

  &.tooltip-top::after {
    border-color: $cmsAltGoldLighter transparent transparent !important;
  }

  &.tooltip-left::after {
    border-color: #0000 #0000 #0000 $cmsAltGoldLighter !important;
  }

  &.tooltip-right::after {
    border-color: transparent $cmsAltGoldLighter transparent transparent !important;
  }
}

button {
  &.action-btn-disabled-grey {
    &[disabled] {
      background-color:#515b69 !important;
      border: 1px solid #515b69 !important;
    }
  }
}

.cancel-error-animation {
  ng-component {
    .help-info {
      animation-name: none;
      height: auto;
    }
  }
}

.text-danger {
  color: $cmsDanger;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0071bc;
}

.mat-checkbox.mat-primary .mat-checkbox-frame {
  border: 1px solid #a9b0b4 !important;
}

.mat-checkbox-disabled {
  opacity: 0.4 !important;
}
